/* link */
a {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    text-decoration: none;
}

a:focus {
    text-decoration: none;
}

/* required */
span.required {
    display: inline-block;
    vertical-align: top;
    color: #d32f2f;
    margin-left: 5px;
    padding-right: 8px;
    font-size: 2rem;
}

/* loader */
.dots-loader {
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
}

.dots-loader .dot {
    height: 25px;
    width: 25px;
    background-color: #455a64;
    margin: 10px;
    border-radius: 100%;
}

.dots-loader .dot:nth-child(1) {
    animation: bounce 1s linear infinite;
}

.dots-loader .dot:nth-child(2) {
    animation: bounce 1s linear 0.3s infinite;
}

.dots-loader .dot:nth-child(3) {
    animation: bounce 1s linear 0.6s infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(20px);
        opacity: 0.5;
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(20px);
        opacity: 0.8;
    }
}

/* s alert */
.app-alert {
    z-index: 1000001;
    font-weight: 600;
}

.s-alert-wrapper {
    z-index: 1000001;
    display: flex;
    justify-content: center;
    position: relative;
}

.s-alert-wrapper .s-alert-box {
    padding: 12px 25px;
    min-width: 250px;
    border-radius: 9px;
    text-align: center;
}

.s-alert-wrapper .s-alert-box-inner span {
    font-size: 14px;
    font-weight: 700;
}

.s-alert-wrapper .s-alert-box-inner span i {
    margin-right: 8px;
}

.s-alert-wrapper .s-alert-success {
    background: #2e7d32;
}

.s-alert-wrapper .s-alert-info {
    background: #37474f;
}

.s-alert-wrapper .s-alert-info span i {
    color: #64dd17;
}

.s-alert-wrapper .s-alert-error {
    background: #c62828;
}

/* material UI */
.MuiInputLabel-outlined.MuiInputLabel-shrink,
.MuiPickersBasePicker-pickerView {
    background: #ffffff;
}
