html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	border: 0;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	font-weight: inherit;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}

html {
	font-size: 62.5%; /* Corrects text resizing oddly in IE6/7 when body font-size is set using em units http://clagnut.com/blog/348/#c790 */
	overflow-y: scroll; /* Keeps page centered in all browsers regardless of content height */
	-webkit-text-size-adjust: 100%; /* Prevents iOS text size adjust after orientation change, without disabling user zoom */
	-ms-text-size-adjust:     100%; /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
	box-sizing: border-box; /* Apply a natural box layout model to the document; see http://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
}
*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background: #fff; /* Fallback for when there is no custom background color defined. */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

table { /* tables still need 'cellspacing="0"' in the markup */
	border-collapse: separate;
	border-spacing: 0;
}

caption,
th,
td {
	font-weight: normal;
	text-align: left;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
}

blockquote,
q {
	quotes: "" "";
}

a:focus {
	outline: thin dotted;
}

a:hover,
a:active {
	outline: 0;
}

a img {
	border: 0;
}

body {
	overflow-y: hidden;
	color: #212121;
}

body,
button,
input,
select,
textarea {
	color: #404040;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212121;
}